import { css } from '@emotion/react'
import { mTheme } from '../../../style/themes'

export const featuredEventsContainer: Style = css({
  height: 'fit-content',
  // padding: size.M,
  // border: `3px solid ${borderColor.tertiary}`,
  // borderRadius: radius.primary,
  // background: `radial-gradient(
  //   circle farthest-corner at left top,
  //   #380c20,
  //   rgba(1,1,1,0.6)
  // )`,
  [mTheme.breakpoint.belowLarge]: {
    padding: 0,
    border: 'none',
    background: 'transparent',
  },
  [mTheme.breakpoint.large]: {
    maxWidth: mTheme.pageWidth.LAccountedForPadding,
  },
})

export const heading: Style = css({
  margin: 0,
  width: 'fit-content',
  fontWeight: 'bold',
})

export const headingContainer: Style = css({
  display: 'flex',
  alignItems: 'center',
  paddingTop: 0,
  marginBottom: mTheme.size.XS,
  gap: mTheme.size.XS,
  width: '100%',
})

export const outerMasonryContainer: Style = css({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  [mTheme.breakpoint.belowMedium]: {
    padding: 0,
  },
})

export const masonryContainer: Style = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

export const masonry: Style = css({
  display: 'flex',
  flexDirection: 'column',
  gap: mTheme.size.S,

  [mTheme.breakpoint.medium]: {
    columnCount: 2,
    '> div': {
      marginBottom: mTheme.size.S,
    },
  },
  [mTheme.breakpoint.large]: {
    display: 'block',
    columnCount: 3,
  },
})

export const smallNumberOfEventsContainer: Style = css({
  display: 'flex',
  gap: mTheme.size.S,
  width: '100%',
  [mTheme.breakpoint.belowMedium]: {
    flexDirection: 'column',
  },
})
