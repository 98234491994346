import EventCard from '../../../components/EventCard/EventCard'
import { SelectCityMutatingCompact } from '../../../components/forms/SelectCity/SelectCity'
import { useGetLatestEventsQuery } from '../../../graphql/queries/main/mainFeed.query.gen'
import { useCurrentUser } from '../../../graphql/hooks'

import * as styles from './FeaturedEvents.styles'
import { Link } from '@remix-run/react'

const FeaturedEvents: React.FC = () => {
  const { city } = useCurrentUser()
  const { data } = useGetLatestEventsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      cityId: city.id,
      pagination: {
        first: 10,
      },
    },
  })

  const eventEdges = data?.getLatestEvents.edges

  return (
    <div css={styles.featuredEventsContainer}>
      <div css={styles.headingContainer}>
        <h3 css={styles.heading}>Latest events in</h3>
        <SelectCityMutatingCompact />
      </div>

      <div css={styles.outerMasonryContainer}>
        <div css={styles.masonryContainer}>
          {eventEdges && (
            <section
              css={
                eventEdges.length > 3
                  ? styles.masonry
                  : styles.smallNumberOfEventsContainer
              }
            >
              {eventEdges.map((edge) => (
                <EventCard
                  key={edge.node.id}
                  event={edge.node}
                  displayMenu={false}
                />
              ))}

              {eventEdges.length === 0 && (
                <p>
                  Nothing here yet.{' '}
                  <Link to="/signup">Sign up and create your event.</Link>
                </p>
              )}
            </section>
          )}
        </div>
      </div>
    </div>
  )
}

export default FeaturedEvents
